import React, { Component } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import DeleteDialog from './DeleteDialog/DeleteDialog.js';
import PeriodForm from './PeriodForm/PeriodForm';
import ItemForm from './ItemForm/ItemForm';
import LogIn from './LogIn/LogIn';
import axios from '../shared/axios-url';
import sharedEnv from "../shared/sharedEnv";
import './Admin.css';

function desc(a, b, order, orderBy) {
  if (orderBy === 'startYear') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  else if (orderBy === 'category') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    if (b[orderBy] === a[orderBy]) {
      if (order === 'desc') {
        return -desc(a, b, order, 'startYear');
      }
      return desc(a, b, order, 'startYear');
    }
    return 0;
  }
  else {
    if (b[orderBy].el < a[orderBy].el) {
      return -1;
    }
    if (b[orderBy].el > a[orderBy].el) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, order, orderBy) : (a, b) => -desc(a, b, order, orderBy);
}

class Admin extends Component {

  state = {
    currentTab: 0,
    periods: [],
    data: [],
    availableCategories: [
      'Γεγονός',
      'Έκθεμα'
    ],
    headers: [
      { id: 'title', label: 'Τίτλος' },
      { id: 'startYear', label: 'Έτος έναρξης' },
      { id: 'description', label: 'Περιγραφή' },
      { id: 'category', label: 'Κατηγορία' }
    ],
    order: 'asc',
    orderBy: 'startYear',
    page: 0,
    rowsPerPage: 20,
    selectedPeriod: '',
    periodDialogOpen: false,
    selectedItem: '',
    itemDialogOpen: false,
    editItem: false,
    deleteDialogOpen: false,
    snackbarTrueOpen: false,
    snackbarFalseOpen: false
  };

  componentDidMount() {
    if (this.isAuthed()) {
      this.userHasAuthed();
    }
  }

  getItems() {
    axios.instance.get('/items')
    .then((items) => {
      let data = [];
      for (let item of items.data) {
        for (let imageUrl of item.imageUrls) {
          imageUrl.url = sharedEnv.baseURL + '/' + imageUrl.url;
        }
        item.category = this.state.availableCategories[item.category];
        data.push(item);
      }
      this.setState({ data });
    },
    (error) => {
      console.log(error);
    });
  }

  getPeriods() {
    axios.instance.get('/periods')
    .then((periods) => {
      this.setState({ periods: periods.data });
    },
    (error) => {
      console.log(error);
    });
  }

  getToken() {
    const loginTime = new Date(localStorage.getItem('login-time')).valueOf();

    if (new Date().valueOf() - loginTime >= 10800000) {
      localStorage.clear();
    }
    return localStorage.getItem('x-auth');
  }

  isAuthed() {
    return this.getToken() !== null;
  }

  userHasAuthed() {
    this.getItems();
    this.getPeriods();
  }

  handleTabChange = (event, value) => {
    this.setState({ currentTab: value });
  };

  handleRequestSort = (event, orderBy) => {
    let order = 'asc';
    if (this.state.orderBy === orderBy && this.state.order === 'asc') {
      order = 'desc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getYear(start, end) {
    if (start < -6000) {
      return '';
    }

    if (end) {
      if (start < 0 && end >= 0) {
        return (-1 * start) + ' π.Χ. - ' + end + ' μ.Χ.';
      }
      else if (start < 0 && end < 0) {
        return (-1 * start) + ' - ' + (-1 * end) + ' π.Χ.';
      }
      else {
        return start + ' - ' + end + ' μ.Χ.';
      }
    }

    if (start < 0) {
      return (-1 * start) + ' π.Χ.';
    }
    else {
      return start + ' μ.Χ.';
    }
  };

  getDescription(description) {
    if (description.length > 170) {
			const limit = description.substr(0, 170).lastIndexOf(' ');
			return description.substr(0, limit) + ' ...';
		}
		return description;
  }

  itemDialogOpen = () => {
    this.setState({ itemDialogOpen: true });
  };

  itemDialogEdit = (id) => {
    this.setState({
      selectedItem: id,
      itemDialogOpen: true,
      editItem: true
    });
  };

  itemDialogClose = () => {
    this.setState({
      selectedItem: '',
      itemDialogOpen: false,
      editItem: false
    }, () => {
      this.getItems();
    });
  };

  periodDialogOpen = (id) => {
    this.setState({
      selectedPeriod: id,
      periodDialogOpen: true
    });
  };

  periodDialogClose = () => {
    this.setState({
      selectedPeriod: '',
      periodDialogOpen: false
    }, () => {
      this.getPeriods();
    });
  };

  deleteDialogOpen = (id) => {
    this.setState({
      selectedItem: id,
      deleteDialogOpen: true
    });
  };

  deleteDialogClose = () => {
    this.setState({
      selectedItem: '',
      deleteDialogOpen: false
    }, () => {
      this.getItems();
    });
  };

  resetClicked = (isTrue) => {
    if (isTrue) {
      this.setState({ snackbarTrueOpen: true }, () => {
        setTimeout(() => {
          this.setState({ snackbarTrueOpen: false });
        }, 3000);
      });
    }
    else {
      this.setState({ snackbarFalseOpen: true }, () => {
        setTimeout(() => {
          this.setState({ snackbarFalseOpen: false });
        }, 3000);
      });
    }
  };

  TransitionUp(props) {
    return <Slide direction="up" {...props} />;
  }

  render() {
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.data.length - this.state.page * this.state.rowsPerPage);

    return (
      <div className="AdminPage" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/backgrounds/'+ sharedEnv.backgroundImageFileName +')' }}>
        {!this.isAuthed() ?
          <LogIn
            hasAuthed={this.userHasAuthed.bind(this)}
            resetClicked={this.resetClicked.bind(this)}
          />
        :
          <div className="AdminContainer">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className="AdminTabs"
            >
              <Tab className="AdminTab" label="Αντικειμενα"/>
              <Tab className="AdminTab" label="Περιοδοι"/>
            </Tabs>

            <Paper className="AdminPaper">
              {this.state.currentTab === 0 &&
              <Table className="AdminTable">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {this.state.headers.map(
                      header => (
                        <TableCell
                          key={header.id}
                          sortDirection={this.state.orderBy === header.id ? this.state.order : false}
                        >
                          <TableSortLabel
                            active={this.state.orderBy === header.id}
                            direction={this.state.order}
                            onClick={event => this.handleRequestSort(event, header.id)}
                          >
                            {header.label}
                          </TableSortLabel>
                        </TableCell>
                      ),
                      this,
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(this.state.data, getSorting(this.state.order, this.state.orderBy))
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map(item => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={item._id}
                        >
                          <TableCell>
                            <IconButton className="IconButton"
                              aria-label="Delete"
                              onClick={event => this.deleteDialogOpen(item._id)}
                              style={{  marginTop: '-3px' }} // marginBottom: '10%',
                            >
                              <img alt="delete" style={{ maxWidth: '22px' }} src={process.env.PUBLIC_URL + "/images/delete.svg"}/>
                            </IconButton>
                            <IconButton className="IconButton"
                              aria-label="Edit"
                              onClick={event => this.itemDialogEdit(item._id)}
                            >
                              <img alt="delete" style={{ maxWidth: '20px' }} src={process.env.PUBLIC_URL + "/images/edit.svg"}/>
                            </IconButton>
                          </TableCell>
                          <TableCell className="title-cell">{item.title.el}</TableCell>
                          <TableCell className="year-cell">{this.getYear(item.startYear)}</TableCell>
                          <TableCell>{this.getDescription(item.description.el)}</TableCell>
                          <TableCell>{item.category}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      count={this.state.data.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      backIconButtonProps={{'aria-label': 'Previous Page'}}
                      nextIconButtonProps={{'aria-label': 'Next Page'}}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>}

              {this.state.currentTab === 1 &&
              <Table className="AdminTable">
                <TableHead>
                  <TableRow>
                    <TableCell className="AdminHead miniCol"></TableCell>
                    <TableCell className="AdminHead mediumCol">Τίτλος</TableCell>
                    <TableCell className="AdminHead mediumCol">Έτη</TableCell>
                    <TableCell className="AdminHead">Περιγραφή</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.periods.map((period, index) => (
                    <TableRow
                      className="AdminPeriodRow"
                      hover
                      tabIndex={-1}
                      key={period._id}
                    >
                      <TableCell className="miniCol">
                        <IconButton className="IconButton"
                          aria-label="Edit"
                          onClick={event => this.periodDialogOpen(period._id)}
                        >
                          <img alt="delete" style={{ maxWidth: '20px' }} src={process.env.PUBLIC_URL + "/images/edit.svg"}/>
                        </IconButton>
                      </TableCell>
                      <TableCell className="mediumCol title-cell">{period.title.el}</TableCell>
                      <TableCell className="mediumCol year-cell">{index === 0 ? '' : this.getYear(period.startYear, period.endYear)}</TableCell>
                      <TableCell>{this.getDescription(period.description.el)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>}
            </Paper>

            {this.state.currentTab === 0 &&
              <div className="AddButton">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.itemDialogOpen}
                  className="NoRadiusButton"
                >
                  Προσθηκη νεου
                </Button>
              </div>
            }
          </div>
        }

        {this.isAuthed() && <ItemForm
          isOpen={this.state.itemDialogOpen}
          isEdit={this.state.editItem}
          item={this.state.selectedItem}
          periods={this.state.periods}
          handleClose={this.itemDialogClose.bind(this)}
          getToken={this.getToken.bind(this)}
        />}
        {this.isAuthed() && <PeriodForm
          isOpen={this.state.periodDialogOpen}
          period={this.state.selectedPeriod}
          handleClose={this.periodDialogClose.bind(this)}
          getToken={this.getToken.bind(this)}
        />}
        {this.isAuthed() && <DeleteDialog
          isOpen={this.state.deleteDialogOpen}
          handleClose={this.deleteDialogClose.bind(this)}
          item={this.state.selectedItem}
          getToken={this.getToken.bind(this)}
        />}

        <Snackbar
          className="Snackbar"
          open={this.state.snackbarTrueOpen}
          TransitionComponent={this.TransitionUp}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          message={<span id="message-id">Μόλις λάβατε email!</span>}
        />
        <Snackbar
          className="Snackbar"
          open={this.state.snackbarFalseOpen}
          TransitionComponent={this.TransitionUp}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          message={<span id="message-id">Κάτι πήγε λάθος, προσπαθήστε ξανά!</span>}
        />
      </div>
    )
  }
}

export default Admin;
