import axios from 'axios';
import sharedEnv from "./sharedEnv";

const instance = axios.create({
  baseURL: sharedEnv.baseURL
});

export default {
  instance,
};
