import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import CloseIcon from '@material-ui/icons/Close';

import axios from '../../shared/axios-url';
import './ImageUpload.css';
import sharedEnv from "../../shared/sharedEnv";

class ImageUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      removed: [],
      receivedProps: false
    };
  }

  componentDidUpdate() {
    if (this.props.images.length > 0 && !this.state.receivedProps) {
      this.setState({
        files: [...this.props.images],
        // removed: [],
        receivedProps: true
      });
    }
    else if (this.props.images.length === 0 && this.state.receivedProps) {
      this.setState({
        files: [],
        // removed: [],
        receivedProps: false
      });
    }
    else if (this.props.submitted) {
      this.setState({
        files: [],
        removed: [],
        receivedProps: true
      });
    }
  }

  onDrop(droppedFiles) {
    const num = Math.floor(Math.random() * 1000000000) + 1;
    const reader = new FileReader();

    reader.readAsDataURL(droppedFiles[0]);
    reader.onload = () => {
      Object.assign(droppedFiles[0], {
        id: 'file-' + num,
        preview: URL.createObjectURL(droppedFiles[0]),
        base64: reader.result
      });

      let imageToRemove = [];
      let updatedRemoved = [...this.state.removed];
      if (this.state.files.length > 0 && this.state.files[0].preview.indexOf(sharedEnv.baseURL) > -1) {
        imageToRemove = [this.state.files[0].preview.replace(sharedEnv.baseURL, '')];
      }
      if (imageToRemove.length > 0) {
        updatedRemoved.push(imageToRemove[0]);
      }

      this.setState({
        files: droppedFiles,
        removed: updatedRemoved
      }, () => {
        this.props.handleImages(this.state.files, this.state.removed);
      });
    };
    reader.onabort = () => console.log('File reading was aborted');
    reader.onerror = () => console.log('File reading has failed');
  }

  onRemove = () => {
    let updatedFiles = [...this.state.files];
    let updatedRemoved = [...this.state.removed];

    if (updatedFiles[0].base64 === '') {
      updatedRemoved.push(updatedFiles[0].preview.replace(sharedEnv.baseURL, ''));
    }

    this.setState({
      files: [],
      removed: updatedRemoved
    }, () => {
      this.props.handleImages(this.state.files, this.state.removed);
    });
  }

  componentWillUnmount() {
    this.state.files.forEach(f => URL.revokeObjectURL(f.preview));
  }

  render() {
    return (
      <section className="section">
        <div className="dropzone">
          <Dropzone accept="image/jpeg, image/jpg" multiple={false} onDrop={this.onDrop.bind(this)}>
            {({getRootProps, getInputProps}) => {
              return (
                <div className="dropText" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="icon-part1"></div>
                  <div className="icon-part2"></div>
                  <p>Σύρετε είκονα εδώ ή πατήστε για να επιλέξετε.</p>
                </div>
              )
            }}
          </Dropzone>
        </div>
        <div className="previews">
          {this.state.files.map((file, index) => (
            <div key={index}
              className="preview"
              style={{ boxShadow: '6px 6px rgba(' + this.props.color + ', 1)'}}
            >
              <div className="remove">
                <CloseIcon onClick={() => this.onRemove()}
                  style={{ fontSize: 18, color: '#100a0a' }}/>
              </div>
              <div className="previewInner">
                <img
                  className="img"
                  alt="uploaded"
                  src={file.preview}
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default ImageUpload;
