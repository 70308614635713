import React, { Component } from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import axios from '../../shared/axios-url';
import './ResetPassword.css';
import sharedEnv from "../../shared/sharedEnv";

class ResetPassword extends Component {

  state = {
    email: {
      value: '',
      valid: false,
      touched: false
    },
    password: {
      value: '',
      valid: false,
      touched: false
    },
    confirm: {
      value: '',
      valid: false,
      touched: false
    },
    hasExpired: false,
    hasError: false
  };

  componentDidMount() {
    if (this.props.match.params.token) {
      axios.instance.get('/resetToken/' + this.props.match.params.token)
      .then((response) => {
        // console.log(response);
      },
      (error) => {
        this.setState({
          hasExpired: true
        });
      });
    }
  }

  handleChange = (element) => event => {
    let updatedState = {...this.state};
    updatedState[element].touched = true;
    updatedState[element].value = event.target.value;
    updatedState[element].valid = event.target.value.length > 0;

    if (element === 'password' || element === 'confirm') {
      updatedState.confirm.valid = event.target.value.length > 0
        && updatedState.password.value === updatedState.confirm.value;
    }

    this.setState(updatedState);
  };

  changePassword = (event) => {
    event.preventDefault();

    let userData = {
      email: this.state.email.value,
      password: this.state.password.value
    };

    axios.instance.post('/users/' + this.props.match.params.token, userData)
    .then((response) => {
      this.setState({
        hasError: false
      }, () => {
        localStorage.clear();
        this.props.history.replace('/admin');
      });
    },
    (error) => {
      this.setState({
        hasError: true
      });
    });
  };

  render() {
    return (
      <div>
        {this.state.hasExpired &&
          <p className="ResetError">
            Κάτι πήγε στραβά.<br/>
            Εάν ακόμα επιθυμείτε να αλλάξετε τον κωδικό σας, παρακαλώ ζητήστε νέο email επαναφοράς.
          </p>
        }
        {!this.state.hasExpired &&
          <div className="ResetPage" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/backgrounds/'+ sharedEnv.backgroundImageFileName +')' }}>
            <Paper className="LogInPaper">
              <p className="LogInTitle">ΑΛΛΑΓΗ ΚΩΔΙΚΟΥ</p>
              <form className="LogInForm" onSubmit={this.changePassword}>
                <FormControl className="LogInFormControl">
                  <TextField
                    className="LogInTextField"
                    type="email"
                    label="Email"
                    value={this.state.email.value}
                    onChange={this.handleChange('email')}
                    margin="normal"
                    required={true}
                  />
                  {this.state.email.touched && !this.state.email.valid &&
                  <FormHelperText className="HelperText">
                    Υποχρεωτικό πεδίο
                  </FormHelperText>}
                </FormControl>

                <FormControl className="LogInFormControl">
                  <TextField
                    className="LogInTextField"
                    label="Password"
                    value={this.state.password.value}
                    onChange={this.handleChange('password')}
                    margin="normal"
                    required={true}
                    type='password'
                  />
                  {this.state.password.touched && !this.state.password.valid &&
                  <FormHelperText className="HelperText">
                    Υποχρεωτικό πεδίο
                  </FormHelperText>}
                </FormControl>

                <FormControl className="LogInFormControl">
                  <TextField
                    className="LogInTextField"
                    label="Confirm Password"
                    value={this.state.confirm.value}
                    onChange={this.handleChange('confirm')}
                    margin="normal"
                    required={true}
                    type='password'
                  />
                  {this.state.confirm.touched && !this.state.confirm.valid &&
                  <FormHelperText className="HelperText">
                    Υποχρεωτικό πεδίο, απαιτεί ίδια τιμή με τον κωδικό
                  </FormHelperText>}
                </FormControl>

                <br/><br/><br/>
                <div className="ButtonWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="NoRadiusButton"
                    >
                      Αλλαγη
                  </Button>
                </div>
                {this.state.hasError &&
                  <p className="LogInError">Η αλλαγή δεν μπόρεσε να ολοκληρωθεί.</p>}
              </form>
            </Paper>
          </div>
        }
      </div>
    )
  }
}

export default ResetPassword;
