import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import axios from '../../shared/axios-url';
import './PeriodForm.css';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getCleanState() {
  return {
    period: {
      title: {
        value: '',
        valid: false,
        touched: false
      },
      descriptionEl: {
        value: '',
        valid: false,
        touched: false
      },
      descriptionEn: {
        value: '',
        valid: false,
        touched: false
      },
      startYear: {
        value: '',
        valid: false,
        touched: false
      },
      endYear: {
        value: '',
        valid: false,
        touched: false
      },
      startEra: {
        value: '',
        valid: false,
        touched: false
      },
      endEra: {
        value: '',
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    loading: false,
    hasError: false,
    errorCode: 0
  };
}

class PeriodForm extends Component {

  constructor(props) {
    super(props);
    this.state = getCleanState();    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.period !== prevProps.period && this.props.period !== '') {
      axios.instance.get('/periods/' + this.props.period)
      .then((period) => {
        let startYear = -1 * period.data.startYear;
        let startEra = 'π.Χ.';
        if (period.data.startYear >= 0) {
          startYear = period.data.startYear;
          startEra = 'μ.Χ.';
        }

        let endYear = '';
        let endEra = 'π.Χ.';
        if (period.data.endYear) {
          endYear = -1 * period.data.endYear;
          if (period.data.endYear >= 0) {
            endYear = period.data.endYear;
            endEra = 'μ.Χ.';
          }
        }

        let newPeriod =  {
          title: {
            value: {
              el: period.data.title.el,
              en: period.data.title.en
            },
            valid: true,
            touched: false
          },
          descriptionEl: {
            value: period.data.description.el,
            valid: this.wordCounter(period.data.description.el),
            touched: false
          },
          descriptionEn: {
            value: period.data.description.en,
            valid: this.wordCounter(period.data.description.en),
            touched: false
          },
          startYear: {
            value: startYear,
            valid: true,
            touched: false
          },
          endYear: {
            value: endYear,
            valid: true,
            touched: false
          },
          startEra: {
            value: startEra,
            valid: true,
            touched: false
          },
          endEra: {
            value: endEra,
            valid: true,
            touched: false
          }
        };

        this.setState({
          period: newPeriod
        }, () => {
          this.checkFormValidity();
        });
      },
      (error) => {
        console.log(error);
      });
    }
  }

  checkFormValidity() {
    let formIsValid = true;
    for (let periodElement in this.state.period) {
      formIsValid = formIsValid && this.state.period[periodElement].valid;
    }

    this.setState({
      formIsValid: formIsValid
    });
  }

  isFormTouched() {
    let formIsTouched = false;
    for (let periodElement in this.state.period) {
      formIsTouched = formIsTouched || this.state.period[periodElement].touched;
    }
    return formIsTouched;
  }

  handleChange = (element) => event => {
    let updatedPeriod = {...this.state.period};
    updatedPeriod[element].touched = true;

    if (element.indexOf('description') > -1) {
      updatedPeriod[element].value = event.target.value;
      updatedPeriod[element].valid = this.wordCounter(event.target.value);
    }
    else if (element.indexOf('Year') > -1) {
      const regex = /^[0-9\b]+$/;
      if (event.target.value === '' || (regex.test(event.target.value) && event.target.value.length < 5)) {
        updatedPeriod[element].value = event.target.value;
      }
    }
    else {
      let value = 'π.Χ.';
      if (event.target.id === '2' || event.target.id === '4') {
        value = 'μ.Χ.';
      }

      updatedPeriod[element].value = value;
      updatedPeriod[element].valid = true;
    }

    if (element.indexOf('Year') > -1 || element.indexOf('Era') > -1) {
      let elementYear = 'startYear';
      let elementEra = 'startEra';
      if (element.indexOf('end') > -1) {
        elementYear = 'endYear';
        elementEra = 'endEra';
      }

      if (updatedPeriod[elementEra].value === 'μ.Χ.') {
        updatedPeriod[elementYear].valid = updatedPeriod[elementYear].value !== '' && Number(updatedPeriod[elementYear].value) < new Date().getFullYear();
      }
      else {
        updatedPeriod[elementYear].valid = updatedPeriod[elementYear].value !== '' && Number(updatedPeriod[elementYear].value) < 6000;
      }

      if (updatedPeriod.startYear.value !== '' && updatedPeriod.endYear.value !== '') {
        let startYear = Number(this.state.period.startYear.value);
        let endYear = Number(this.state.period.endYear.value);

        if (this.state.period.startEra.value === 'π.Χ.') {
          startYear = -1 * startYear;
        }
        if (this.state.period.endEra.value === 'π.Χ.') {
          endYear = -1 * endYear;
        }

        if (elementYear === 'startYear') {
          updatedPeriod.startYear.valid = updatedPeriod.startYear.valid && startYear < endYear;
        }
        if (elementYear === 'endYear') {
          updatedPeriod.endYear.valid = updatedPeriod.endYear.valid && startYear < endYear;
          
          if (updatedPeriod.startEra.value === 'μ.Χ.') {
            updatedPeriod.startYear.valid = startYear < new Date().getFullYear();
          }
          else {
            updatedPeriod.startYear.valid = startYear < 6000;
          }
        }
      }
    }

    this.setState({
      period: updatedPeriod
    }, () => {
      this.checkFormValidity();
    });
  }

  editPeriod = (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    let newData = {
      description: {
        el: this.state.period.descriptionEl.value,
        en: this.state.period.descriptionEn.value
      }
    };

    if (this.state.period.startYear.touched || this.state.period.endYear.touched) {
      let startYear = Number(this.state.period.startYear.value);
      let endYear = Number(this.state.period.endYear.value);

      if (this.state.period.startEra.value === 'π.Χ.') {
        startYear = -1 * startYear;
      }
      if (endYear && this.state.period.endEra.value === 'π.Χ.') {
        endYear = -1 * endYear;
      }
      newData['startYear'] = startYear;
      newData['endYear'] = endYear;
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth': this.props.getToken()
      }
    };

    axios.instance.post('/periods/' + this.props.period, newData, config)
    .then((result) => {
      this.handleClose();
    },
    (error) => {
      this.setState({
        hasError: true,
        errorCode: error.response.status
      }, () => {
        setTimeout(() => {
          if (this.state.errorCode === 401) {
            localStorage.clear();
            window.location.reload();
          }
          else {
            this.handleClose();
          }
        }, 3000);
      });
    });
  };

  wordCounter(str) {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");

    const count = str.split(' ').length;
    return count >= 100 && count <= 450;
  }

  handleClose = () => {
    this.setState(getCleanState(), () => {
      this.props.handleClose();
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{ paper: 'AdminDialogPaper' }}
      >
        <DialogContent>
          <div className="AdminDialogClose" onClick={this.handleClose}>
            <CloseIcon style={{ fontSize: 30, color: '#595959' }}/>
          </div>

          <form className="period-form" onSubmit={this.editPeriod}>
            <p className="period-form-title">
              {this.state.period.title.value.el} ({this.state.period.title.value.en})
            </p>

            {this.state.period.startYear.value !== 7000 && 
            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΕΤΗ</div>
              <div className="TextField">
                <div className="YearField" style={{ marginRight: '10%' }}>
                  <FormControl style={{ width: '60%' }}>
                    <TextField
                      label="Από"
                      value={this.state.period.startYear.value}
                      onChange={this.handleChange('startYear')}
                      margin="none"
                      required={true}
                      disabled={this.state.period.startYear.value === 6000}
                    />
                    {!this.state.period.startYear.valid && 
                    <FormHelperText className="HelperText">
                      Μη έγκυρο έτος
                    </FormHelperText>}
                  </FormControl>

                  <div className="EraFields">
                    <div className={"EraField " + (this.state.period.startEra.value === 'π.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="1" onClick={this.handleChange('startEra')}
                    >
                      π.Χ.
                    </div>
                    <div className={"EraField " + (this.state.period.startEra.value === 'μ.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="2" onClick={this.handleChange('startEra')} style={{ right: '0' }}
                    >
                      μ.Χ.
                    </div>
                  </div>
                </div>

                <div className="YearField">
                  <FormControl style={{ width: '60%' }}>
                    <TextField
                      label="Έως"
                      value={this.state.period.endYear.value}
                      onChange={this.handleChange('endYear')}
                      margin="none"
                      required={true}
                      disabled={this.state.period.endYear.value === new Date().getFullYear()}
                    />
                    {this.state.period.endYear.touched && !this.state.period.endYear.valid && 
                    <FormHelperText className="HelperText">
                      Μη έγκυρο έτος
                    </FormHelperText>}
                  </FormControl>

                  <div className="EraFields">
                    <div className={"EraField " + (this.state.period.endEra.value === 'π.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="3" onClick={this.handleChange('endEra')}
                    >
                      π.Χ.
                    </div>
                    <div className={"EraField " + (this.state.period.endEra.value === 'μ.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="4" onClick={this.handleChange('endEra')} style={{ right: '0' }}
                    >
                      μ.Χ.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΠΕΡΙΓΡΑΦΗ</div>
              <FormControl className="TextField">
                <TextField
                  label="ΕΛ"
                  value={this.state.period.descriptionEl.value}
                  onChange={this.handleChange('descriptionEl')}
                  margin="none"
                  required={true}
                  multiline
                  rowsMax={5}
                />
                {!this.state.period.descriptionEl.valid && 
                <FormHelperText className="HelperText">
                  Εισάγετε από 100 εώς 450 λέξεις.
                </FormHelperText>}
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  label="ENG"
                  value={this.state.period.descriptionEn.value}
                  onChange={this.handleChange('descriptionEn')}
                  margin="none"
                  required={true}
                  multiline
                  rowsMax={5}
                />
                {!this.state.period.descriptionEn.valid && 
                <FormHelperText className="HelperText">
                  Εισάγετε από 100 εώς 450 λέξεις.
                </FormHelperText>}
              </FormControl>
            </div>

            <br/><br/><br/>
            <div className="Wrapper">
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                className="NoRadiusButton"
                disabled={this.state.loading || !this.state.formIsValid || !this.isFormTouched()}
                >
                  Αποθηκευση
              </Button>
              { this.state.loading && 
                <CircularProgress size={24} 
                  color="primary" 
                  className="ButtonProgress"
                />
              }
            </div>
            {this.state.hasError && (
              this.state.errorCode === 401 ?
                <p className="errorText">Συνδεθείτε και προσπαθήστε ξανά!</p>
              :
                <p className="errorText">Κάτι πήγε στραβά, προσπαθήστε ξανά!</p>
            )}
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

export default PeriodForm;
