const baseURL = window.env.backendURL || 'http://localhost:8094';
const frontURL = window.env.frontendURL || 'http://localhost:8093';

const hrefLink = window.env.hrefLink || "https://kiato.gov.gr"
const hrefAlt = window.env.hrefAlt || "Δήμος Σικυωνίων";
const shareQuote = window.env.shareQuote || 'Dimos Sikionion Timeline';
const appVersion = window.env.appVersion || '0.0.0';

const logoFileName = window.env.logoFileName || 'comic-logo.png';
const faviconFileName = window.env.faviconFileName || 'comic-favicon.ico';
const backgroundImageFileName = window.env.backgroundImageFileName || 'mtpy-background.jpg'

const introTextGR = window.env.introTextGR || "Η ΙΣΤΟΡΙΑ ΜΑΣ ΜΕΣΑ ΑΠΟ\nΤΟ ΠΕΡΑΣΜΑ ΤΩΝ ΧΡΟΝΩΝ";
const introTextEN = window.env.introTextEN || 'OUR HISTORY THROUGH THE YEARS';

export default {
  baseURL,
  frontURL,
  hrefLink,
  hrefAlt,
  shareQuote,
  logoFileName,
  faviconFileName,
  appVersion,
  introTextGR,
  introTextEN,
  backgroundImageFileName
};
