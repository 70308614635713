import React, { Component } from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

class LazyImage extends Component {

  state = {
    loaded: false
  };
  
  componentDidMount() {
    this.setState({
      loaded: false
    });
  }

  imageLoaded = (event) => {
    if (event.target.src.indexOf('spinner.gif') > -1) {
      this.setState({
        loaded: false
      });
    }
    else {
      this.setState({
        loaded: true
      });
    }
  };

  render() {
    let placeholder = process.env.PUBLIC_URL + '/images/spinner.gif';
    let image = {
    	url: placeholder
    };

    if (this.props.image) {
      image = {
        url: this.props.image
      };
    }

    let Img = () => (
      <img
        className={this.state.loaded ? "lazyload dialog-img" : "lazyload dialog-spinner"}
        src={placeholder}
        data-src={image.url}
        alt="loading"
        onLoad={(event) => this.imageLoaded(event)}
        onClick={() => this.props.onFull()}
      />
    );

    return (
      <React.Fragment>
        {this.state.loaded && 
          <div className="dialog-img-full-btn">
            <FullscreenIcon
              style={{ color: 'white' }}
              onClick={() => this.props.onFull()}
            />
          </div>
        }
        {Img()}
      </React.Fragment>
    );
  }
}

export default LazyImage;
