import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';
import Admin from './Admin/Admin';
import ResetPassword from './Admin/ResetPassword/ResetPassword';
import Timeline from './Timeline/Timeline';
import sharedEnv from "./shared/sharedEnv";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#33a095',
      main: '#00897b',
      dark: '#005f56',
      contrastText: 'black'
    },
    secondary: {
      light: '#ce467b',
      main: '#c2185b',
      dark: '#87103f',
      contrastText: 'white'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto'
    ]
  }
});

class App extends Component {

  updateFavicon() {
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = process.env.PUBLIC_URL + '/favicons/' + sharedEnv.faviconFileName;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  componentDidMount() {
    // call api or anything
    this.updateFavicon();
  }

  render() {

    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <BrowserRouter basename="/">
            <Switch>
              <Route path="/admin" exact component={Admin}/>
              <Route path="/resetPassword/:token" exact component={ResetPassword}/>
              <Route path="/:lang/:start?/:end?/:id?" exact component={Timeline}/>
              <Route path="*" component={Timeline}/>
            </Switch>
          </BrowserRouter>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
