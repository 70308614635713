import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import SimpleBar from 'simplebar-react';
import vis from 'vis';
import 'lazysizes';

import ItemDialog from './ItemDialog/ItemDialog';
import Carousel from './Carousel/Carousel';
import axios from '../shared/axios-url';

import 'simplebar/dist/simplebar.min.css';
import './Timeline.css';
import sharedEnv from "../shared/sharedEnv";

class Timeline extends Component {

  timeline;
  state = {
    periods: [],
    itemData: {},
    itemObjects: [],
    activeItem: {
      _id: -1
    },
    activeFilters: {
      range: {
        start: '',
        startBC: true,
        end: '',
        endBC: true
      },
      categories: {
        political: false,
        building: false,
        exhibits: false
      },
      keywords: ''
    },
    validFilters: true,
    activeItemIndex: -1,
    hasNext: true,
    hasPrev: true,
    activePeriods: [],
    activePeriodFocused: false,
    categoryColors: ['#ff7c44', '#00a99c'],
    dialogOpen: false,
    sidebarOpen: false,
    snackbarOpen: false,
    filtersOpen: false,
    currentLang: 'el',
    loadScreen: true,
    viewOk: true
  };

  // check screen dimensions & if url has params
  setView = () => {
    let newLang = 'el';
    if (this.props.match.params.lang === 'en') {
      newLang = this.props.match.params.lang;
    }

    this.setState({
      viewOk: window.innerWidth > window.innerHeight,
      currentLang: newLang,
      dialogOpen: false
    }, () => {
      if (this.props.match.params.start && this.props.match.params.end) {
        const startYear = Number(this.props.match.params.start);
        const endYear = Number(this.props.match.params.end);

        if (startYear && startYear >= -7000 && startYear < new Date().getFullYear() &&
          endYear && endYear > -7000 && endYear <= new Date().getFullYear()) {

          this.timeline.setWindow((new Date(startYear, 0, 1)).setFullYear(startYear), (new Date(endYear, 0, 1)).setFullYear(endYear));

          if (this.props.match.params.id && this.state.viewOk) {
            const itemId = this.props.match.params.id;
            setTimeout(() => {
              this.getDialogItem(itemId);
            }, 1000);
          }
        }
        else {
          this.timeline.setWindow(new Date(-7000, 0, 1), new Date(new Date().getFullYear(), 11, 31));
        }
      }
      else {
        this.timeline.setWindow(new Date(-7000, 0, 1), new Date(new Date().getFullYear(), 11, 31));
      }
    });
  };

  // call setView, init Timeline, get periods and items
  componentDidMount() {
    var container = document.getElementById('timeline');
    var options = {
      stack: false,
      min: new Date(-7000, 0, 1),
      max: new Date(new Date().getFullYear(), 11, 31),
      zoomMin: 88128000000,
      format: {
        minorLabels: (date) => {
          if (date.month() > 0) {
            return ' ';
          }
          if (this.state.currentLang === 'en') {
            return date.year() === 0 ? date.year() : (date.year() < 0 ? (-1 * date.year()) + ' B.C.' : date.year() + ' A.D.');
          }
          return date.year() === 0 ? date.year() : (date.year() < 0 ? (-1 * date.year()) + ' π.Χ.' : date.year() + ' μ.Χ.');
        },
        majorLabels: () => {
          return ' ';
        }
      }
    };

    axios.instance.get('/periods')
      .then((periods) => {

          axios.instance.get('/items')
            .then((items) => {
                let transformed = this.transformItems(items);

                this.setState({
                  periods: periods.data,
                  activePeriods: [...Array(periods.data.length).keys()],
                  itemData: transformed.finalData,
                  itemObjects: transformed.items.data
                }, () => {
                  this.timeline = new vis.Timeline(container, [], options);
                  this.setView();

                  setTimeout(() => {
                    this.setState({
                      loadScreen: false,
                      currentLang: this.props.match.params.lang
                    }, () => {
                      window.addEventListener('resize', (e) => this.setView());
                    });
                  }, 1000);

                  // custom zoom step
                  this.timeline.on('rangechange', (properties) => {
                    if (properties.event) {
                      let zoomStep = 2.5;
                      const currentStep = this.timeline.timeAxis.step.step;
                      if (currentStep === 1 || currentStep === 3) {
                        zoomStep = 15;
                      }

                      if (properties.event.wheelDelta) {
                        if (properties.event.wheelDelta > 0) {
                          this.zoomIn(zoomStep);
                        }
                        else {
                          this.zoomOut(zoomStep);
                        }
                      }
                      else {
                        if (properties.event.detail < 0) {
                          this.zoomIn(zoomStep);
                        }
                        else {
                          this.zoomOut(zoomStep);
                        }
                      }
                    }
                    this.setState({ activePeriodFocused: false });
                  });

                  this.timeline.on('rangechanged', () => {
                    this.onRangeChanged();
                  });

                  // open item's dialog or expand cluster
                  this.timeline.on('click', (properties) => {
                    if (this.state.filtersOpen) {
                      this.setState({
                        filtersOpen: false
                      }, () => {
                        this.closeFilters();
                      });
                    }
                    else if (this.state.sidebarOpen) {
                      this.closeSidebar();
                    }

                    if (properties.what === 'item') {
                      const itemClicked = this.timeline.itemsData.get(properties.item);

                      if (properties.event.srcElement.className.indexOf('cluster') !== -1) {
                        const visibleWindow = this.timeline.getWindow();
                        const interval = (visibleWindow.end - visibleWindow.start) / 8;
                        this.timeline.setWindow(itemClicked.start.valueOf() - interval, itemClicked.start.valueOf() + interval);
                      }
                      else if (properties.event.srcElement.className.indexOf('single') !== -1) {
                        this.getDialogItem(itemClicked.id);
                      }
                      else if (properties.event.srcElement.className.indexOf('multiple') !== -1) {
                        if (properties.event.srcElement.className.indexOf('multiple-slide') !== -1) {
                          this.getDialogItem(properties.event.srcElement.id);
                        }
                        else if (properties.event.srcElement.className.indexOf('no-image') !== -1) {
                          this.getDialogItem(properties.event.path[1].id);
                        }
                        else if (properties.event.srcElement.className.indexOf('card') !== -1) {
                          this.getDialogItem(properties.event.srcElement.parentNode.parentNode.id);
                        }
                      }
                    }
                    this.setState({ activePeriodFocused: false });
                  });
                });
              },
              (error) => {
                console.log(error);
              });
        },
        (error) => {
          console.log(error);
        });
  }

  // show clusters or items
  onRangeChanged() {
    const totalHeight = document.getElementsByClassName('vis-itemset')[0].offsetHeight;
    const visibleWindow = this.timeline.getWindow();
    this.focusPeriods(visibleWindow);

    // show single and multiple items
    if (this.timeline.timeAxis.step.scale === 'month') {
      this.timeline.setItems(new vis.DataSet(this.state.itemData[this.state.currentLang]));

      // init carousels
      const multi =  document.querySelectorAll('.multiple-item');
      for (let i = 0; i < multi.length; i++) {
        if (multi[i].children.length > 0) {
          const carousel = new Carousel({
            selector: multi[i].children[0].children[3],
            loop: true,
            onInit: function() {
              this.addDots();
              this.updateDots();
            },
            onChange: function() {
              this.updateDots()
            }
          });

          multi[i].querySelector('.multiple-prev').addEventListener('click', () => carousel.prev());
          multi[i].querySelector('.multiple-next').addEventListener('click', () => carousel.next());
        }
      }
    }
    // create four clusters
    else {
      // split timeline view field in 4 equal parts ang get their limits
      var interval = visibleWindow.end - visibleWindow.start;
      var quarter = interval / 4;
      var limits = [visibleWindow.start.valueOf()];
      var middles = [];

      for (let i = 1; i <= 4; i++) {
        limits.push(visibleWindow.start.valueOf() + (i * quarter));
      }
      for (let i = 0; i < 4; i++) {
        middles.push((limits[i] + limits[i + 1]) / 2);
      }

      var newDataArray = [];
      for (let i = 0; i < 4; i++) {
        // get each cluster's items
        let clusterData = [];
        for (let item of this.state.itemObjects) {
          if (item.startYear >= new Date(limits[i]).getFullYear() && item.startYear < new Date(limits[i + 1]).getFullYear()) {
            clusterData.push(item);
          }
        }

        if (clusterData.length > 0) {
          const margin = Math.floor(Math.random() * (totalHeight / 5));
          const clusterStyle = 'margin-top: ' + margin + 'px !important;'
          const lineHeight = totalHeight - margin;

          let textClasses = 'card-texts-no-image';
          if (clusterData[0].imageUrls && clusterData[0].imageUrls.length > 0) {
            textClasses = 'card-texts';
          }

          let cardId = clusterData[0]._id;
          let cardClass = 'single';
          if (clusterData.length > 1) {
            cardId = i;
            cardClass = 'cluster';
          }

          const moreTexts = {
            el: 'ΠΕΡΙΣΣΟΤΕΡΑ',
            en: 'SHOW MORE'
          };

          newDataArray.push({
            id: cardId,
            start: new Date(middles[i]),
            className: cardClass + '-item',
            style: clusterStyle,
            content: `
              <div style="height:` + lineHeight + `px;" class="line"></div>
              <div class="card-container"></div>` +
              (cardClass === 'cluster' ? `<div class="cluster-more more1"></div> <div class="cluster-more more2"></div>` : '') +
              `<div class="` + cardClass + ` card-content">` + (
                clusterData[0].imageUrls && clusterData[0].imageUrls.length > 0 ?
                  `<div class="` + cardClass + ` card-img-container">
                  <img class="lazyload ` + cardClass + ` card-img" alt="card" src="` + process.env.PUBLIC_URL + `/images/spinner.gif" data-src="` + clusterData[0].imageUrls[0].url + `"/>
                </div>` : '') +
              `<div class="` + cardClass + ` ` + textClasses + `">
                  <p class="` + cardClass + ` card-title">` + clusterData[0].title[this.state.currentLang] + `</p>
                  <div class="` + cardClass + ` card-category">
                    <div style="height: 100%; width: 50%; background:` + this.state.categoryColors[clusterData[0].category] + `;"></div>
                    <div style="height: 100%; width: 50%; background:` + this.state.categoryColors[clusterData[0].category] + `; opacity: 0.5;"></div>
                  </div>
                  <p class="` + cardClass + ` card-desc">` + clusterData[0].description[this.state.currentLang].split('\n')[0] + `</p>
                </div>` + (cardClass === 'single' ? `
                <div class="` + cardClass + ` card-more-container">
                  <div>` + moreTexts[this.state.currentLang] + `</div>
                </div>` : ``) + `
              </div>
            `
          });
        }
      }

      if (visibleWindow.start.getFullYear() === -7000 && visibleWindow.end.getFullYear() === new Date().getFullYear()) {
        newDataArray = [];
      }
      this.timeline.setItems(new vis.DataSet(newDataArray));
    }
  }

  // create html items for cards or multiple items
  transformItems(items) {
    items.data.sort(function(a, b) {
      return a.startYear - b.startYear;
    });

    let dataByYear = [];
    items.data.forEach(item => {
      for (let imageUrl of item.imageUrls) {
        imageUrl.url = sharedEnv.baseURL + '/' + imageUrl.url;
      }

      // match each year with its item(s)
      let found = dataByYear.find(data => {
        if (data && data.startYear === item.startYear) {
          data.items.push(item);
          return true;
        }
        return false;
      });

      if (!found) {
        dataByYear.push({
          startYear: item.startYear,
          items: [item]
        })
      }
    });

    let finalData = {
      el: [],
      en: []
    };
    const langs = ['el', 'en'];

    for (let data of dataByYear) {
      const margin = Math.floor(Math.random() * 100);
      const cardStyle = 'margin-top: ' + margin + 'px !important;'
      const moreTexts = {
        el: 'ΠΕΡΙΣΣΟΤΕΡΑ',
        en: 'SHOW MORE'
      };

      // single item
      if (data.items.length === 1) {
        let textClasses = 'card-texts-no-image';
        if (data.items[0].imageUrls && data.items[0].imageUrls.length > 0) {
          textClasses = 'card-texts';
        }

        for (let lang of langs) {
          finalData[lang].push({
            id: data.items[0]._id,
            start: (new Date(data.items[0].startYear, 6, 1)).setFullYear(data.items[0].startYear),
            className: 'single-item',
            style: cardStyle,
            content: `
              <div class="line"></div>
              <div class="card-container"></div>
              <div class="single card-content">` + (
                data.items[0].imageUrls && data.items[0].imageUrls.length > 0 ?
                  `<div class="single card-img-container">
                  <img class="lazyload single card-img" alt="card" src="` + process.env.PUBLIC_URL + `/images/spinner.gif" data-src="` + data.items[0].imageUrls[0].url + `"/>
                </div>` : '') +
              `<div class="single ` + textClasses + `">
                  <p class="single card-title">` + data.items[0].title[lang] + `</p>
                  <div class="single card-category">
                    <div style="height: 100%; width: 50%; background:` + this.state.categoryColors[data.items[0].category] + `;"></div>
                    <div style="height: 100%; width: 50%; background:` + this.state.categoryColors[data.items[0].category] + `; opacity: 0.5;"></div>
                  </div>
                  <p class="single card-desc">` + data.items[0].description[lang].split('\n')[0] + `</p>
                </div>
                <div class="single card-more-container">
                <div class="single">` + moreTexts[lang] + `</div>
                </div>
              </div>
            `
          });
        }
      }
      // multiple items on the same year
      else {
        for (let lang of langs) {
          let slides = '';
          for (let item of data.items) {
            slides += `<div class="multiple-slide" id="` + item._id  + `">`;
            if (item.imageUrls && item.imageUrls.length > 0) {
              slides += `<div class="multiple card-img-container">`;
              slides += `<img class="lazyload multiple card-img" alt="card" src="` + process.env.PUBLIC_URL + `/images/spinner.gif" data-src="` + item.imageUrls[0].url + `"/>`;
              slides += `</div>`;
              slides += `<div class="multiple card-texts">`;
            }
            else {
              slides += `<div class="multiple card-texts-no-image">`;
            }
            slides += `<p class="multiple card-title">` + item.title[lang] + `</p>`;
            slides += `<div class="multiple card-category">`;
            slides += `<div style="height: 100%; width: 50%; background:` + this.state.categoryColors[item.category] + `;"></div>`;
            slides += `<div style="height: 100%; width: 50%; background:` + this.state.categoryColors[item.category] + `; opacity: 0.5;"></div>`;
            slides += `</div>`;
            slides += `<p class="multiple card-desc">` + item.description[lang].split('\n')[0] + `</p>`;
            slides += `</div>`;
            slides += `<div class="multiple card-more-container">`;
            slides += `<div>` + moreTexts[lang] + `</div>`;
            slides += `</div>`;
            slides += `</div>`;
          }

          finalData[lang].push({
            id: 'multiple' + data.startYear,
            start: (new Date(data.startYear, 6, 1)).setFullYear(data.startYear),
            className: 'multiple-item',
            style: cardStyle,
            content: `
              <div class="line"></div>
              <div class="multiple card-shade"></div>
              <div class="multiple card-container"></div>
              <div class="siema multiple-siema">`
              + slides +
              `</div>
              <div class="arrow-container">
                <button class="multiple-arrow multiple-prev"></button>
                <button class="multiple-arrow multiple-next"></button>
              </div>
            `
          });
        }
      }
    }

    return {finalData, items};
  }

  // animate opening side menu
  openSidebar = () => {
    this.setState({
      sidebarOpen: true
    }, () => {
      document.getElementById('side-menu').style.width = '6%';
      document.getElementById('side-menu').style.height = '54%';
      document.getElementById('side-menu-container').style.display = 'none';
      document.getElementById('side-menu-table').style.display = 'block';
    });
  }

  // animate closing side menu
  closeSidebar = () => {
    if (this.state.filtersOpen) {
      this.setState({
        filtersOpen: false
      }, () => {
        this.closeFilters();
      });
    }

    this.setState({
      sidebarOpen: false
    }, () => {
      document.getElementById('side-menu').style.width = '3%';
      document.getElementById('side-menu').style.height = '15%';
      document.getElementById('side-menu-container').style.display = 'block';
      document.getElementById('side-menu-table').style.display = 'none';
    });
  }

  toggleFilters = () => {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    }, () => {
      if (this.state.filtersOpen) {
        this.openFilters();
      }
      else {
        this.closeFilters();
      }
    });
  };

  openFilters() {
    document.getElementById('filters').style.display = 'block';
    this.focusFilteredPeriods();
  };

  closeFilters() {
    document.getElementById('filters').style.display = 'none';
  };

  categoryFilterClicked = (value) => {
    let updatedFilters = {...this.state.activeFilters};
    updatedFilters.categories[value] = !this.state.activeFilters.categories[value];

    this.setState({
      activeFilters: updatedFilters
    });
  };

  handleFilterChange = (element) => event => {
    const regex = /^[0-9\b]+$/;
    let updatedFilters = {...this.state.activeFilters};

    if (element === 'keywords') {
      updatedFilters.keywords = event.target.value;
    }
    else {
      if (event.target.value === '' || regex.test(event.target.value)) {
        updatedFilters.range[element] = event.target.value;
      }
    }

    this.setState({
      activeFilters: updatedFilters
    }, () => {
      if (element !== 'keywords') {
        this.focusFilteredPeriods();
      }
    });
  };

  timeFilterIsBc(value, isTrue) {
    let updatedFilters = {...this.state.activeFilters};
    updatedFilters.range[value] = isTrue;

    if (value === 'startBC' && isTrue === false) {
      updatedFilters.range.endBC = false;
    }
    if (value === 'endBC' && this.state.activeFilters.range.startBC === false) {
      updatedFilters.range.endBC = false;
    }

    this.setState({
      activeFilters: updatedFilters
    }, () => {
      this.focusFilteredPeriods();
    });
  }

  // color line based on filter time input
  focusFilteredPeriods() {
    let filterStart = this.state.activeFilters.range.start;
    let filterEnd = this.state.activeFilters.range.end;

    if (filterStart === '') {
      filterStart = -7000;
    }
    else if (this.state.activeFilters.range.startBC) {
      filterStart = -1 * filterStart;
    }
    else {
      filterStart = Number(filterStart);
    }

    if (filterEnd === '') {
      filterEnd = new Date().getFullYear();
    }
    else if (this.state.activeFilters.range.endBC) {
      filterEnd = -1 * filterEnd;
    }
    else {
      filterEnd = Number(filterEnd);
    }

    let focused = [];
    for (let i = 0; i < 13; i++) {
      focused.push(false);
    }

    if (filterStart <= filterEnd) {
      if (filterStart < -6000) {
        filterStart = -6000;
      }

      for (let i = 0; i < 13; i++) {
        const start = this.state.periods[i].startYear;
        const end = this.state.periods[i].endYear;

        if (i === 0) {
          if ((filterStart >= start && filterStart < end) || (filterEnd > start && filterEnd <= end)) {
            focused[i] = true;
          }
        }
        else {
          if ((filterStart < end && end <= filterEnd) || (filterStart <= start && start < filterEnd) || (start <= filterStart && filterEnd <= end)) {
            focused[i] = true;
          }
        }
      }
    }

    let colors = '';
    let focusedIndeces = [];
    for (let i = 0; i < 13; i++) {
      if (focused[i]) {
        focusedIndeces.push(i);
        colors += 'rgb(' + this.state.periods[i].color + ') ' + 7.69 * i + '%, ';
        colors += 'rgb(' + this.state.periods[i].color + ') ' + 7.69 * (i + 1) + '%';
      }
      else {
        colors += '#595959 ' + 7.69 * i + '%, ';
        colors += '#595959 ' + 7.69 * (i + 1) + '%';
      }

      if (i < 12) {
        colors += ', ';
      }
    }

    if (focusedIndeces.length > 0) {
      this.setState({ validFilters: true });
      document.getElementById('left-handle').style.display = 'block';
      document.getElementById('right-handle').style.display = 'block';
      document.getElementById('left-handle').style.left = 7.69 * focusedIndeces[0] + '%';
      document.getElementById('right-handle').style.left = 7.69 * (focusedIndeces[focusedIndeces.length - 1] + 1) + '%';
    }
    else {
      this.setState({ validFilters: false });
      document.getElementById('left-handle').style.display = 'none';
      document.getElementById('right-handle').style.display = 'none';
    }
    document.getElementById('colored-line').style.background = 'linear-gradient(to right, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-o-linear-gradient(left, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-ms-linear-gradient(left, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-moz-linear-gradient(left, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-webkit-linear-gradient(left, ' + colors + ')';
  }

  applyFilters() {
    let categories = [];
    if (this.state.activeFilters.categories.political) {
      categories.push(0);
    }
    if (this.state.activeFilters.categories.building) {
      categories.push(1);
    }
    if (this.state.activeFilters.categories.exhibits) {
      categories.push(2);
    }

    let stateRange = {...this.state.activeFilters.range};
    let range = {
      start: -6000,
      end: new Date().getFullYear()
    }
    if (stateRange.start !== '') {
      range.start = Number(stateRange.start);
      if (stateRange.startBC) {
        range.start = -1 * range.start;
      }
    }
    if (stateRange.end !== '') {
      range.end = Number(stateRange.end);
      if (stateRange.endBC) {
        range.end = -1 * range.end;
      }
    }

    axios.instance.post('/filteredItems', {
      categories: categories,
      range: range,
      keywords: this.state.activeFilters.keywords
    })
      .then((items) => {
          let transformed = this.transformItems(items);
          this.setState({
            itemData: transformed.finalData,
            itemObjects: transformed.items.data
          }, () => {
            if (range.start === -6000 && range.end === new Date().getFullYear() && this.state.itemObjects.length > 0) {
              range.start = this.state.itemObjects[0].startYear - 25;
              range.end = this.state.itemObjects[this.state.itemObjects.length - 1].startYear + 25;
            }
            const start = (new Date(range.start, 0, 1)).setFullYear(range.start);
            const end = (new Date(range.end + 1, 0, 1)).setFullYear(range.end);
            this.timeline.setWindow(start, end);
            this.onRangeChanged();
          });
        },
        (error) => {
          console.log(error);
        });
  }

  clearFilters() {
    axios.instance.get('/items')
      .then((items) => {
          let transformed = this.transformItems(items);
          this.setState({
            itemData: transformed.finalData,
            itemObjects: transformed.items.data,
            activeFilters: {
              range: {
                start: '',
                startBC: true,
                end: '',
                endBC: true
              },
              categories: {
                political: false,
                building: false,
                exhibits: false
              },
              keywords: ''
            },
            validFilters: true
          }, () => {
            const start = new Date(-7000, 0, 1);
            const end = new Date(new Date().getFullYear(), 11, 31);
            this.timeline.setWindow(start, end);
            this.focusFilteredPeriods();
          });
        },
        (error) => {
          console.log(error);
        });
  }

  move(direction) {
    var range = this.timeline.getWindow();
    var interval = range.end - range.start;
    this.timeline.setWindow(range.start.valueOf() - interval * direction, range.end.valueOf() - interval * direction);
  }

  zoomIn(percentage) {
    var range = this.timeline.getWindow();
    var start = range.start.valueOf();
    var end = range.end.valueOf();
    var interval = end - start;
    var newInterval = interval / (1 + percentage);
    var distance = (interval - newInterval) / 2;
    var newStart = start + distance;
    var newEnd = end - distance;

    this.timeline.setWindow(newStart, newEnd);
  }

  zoomOut(percentage) {
    var range = this.timeline.getWindow();
    var start = range.start.valueOf();
    var end = range.end.valueOf();
    var interval = end - start;
    var newStart = start - interval * percentage / 2;
    var newEnd = end + interval * percentage / 2;

    this.timeline.setWindow(newStart, newEnd);
  }

  menuClick = (num) => () => {
    let zoomStep = 2.5;
    const currentStep = this.timeline.timeAxis.step.step;
    if (currentStep === 1 || currentStep === 3) {
      zoomStep = 15;
    }

    if (num === 1) { // zoom in
      this.zoomIn(zoomStep);
    }
    else if (num === 2) { // zoom out
      this.zoomOut(zoomStep);
    }
    else if (num === 3) { // left
      this.move(1);
    }
    else if (num === 4) { // right
      this.move(-1);
    }
  };

  periodClick = (num) => () => {
    if (!(this.state.activePeriods.indexOf(num) > -1 && this.state.activePeriods.length === 1)) {
      this.setState({ activePeriodFocused: false });
    }

    for (let i = 0; i < 13; i++) {
      if (num === i) {
        const start = (new Date(this.state.periods[i].startYear, 0, 1)).setFullYear(this.state.periods[i].startYear);
        const end = (new Date(this.state.periods[i].endYear, 0, 1)).setFullYear(this.state.periods[i].endYear);
        this.timeline.setWindow(start, end);
        this.undoFocusPeriod([i]);
        break;
      }
    }
  };

  focusPeriods(visibleWindow) {
    const visibleStart = visibleWindow.start.getFullYear();
    const visibleEnd = visibleWindow.end.getFullYear();
    let focused = [];

    // apply color on focused periods
    if (this.state.periods.length > 0) {
      for (let i = 0; i < 13; i++) {
        const start = this.state.periods[i].startYear;
        const end = this.state.periods[i].endYear;

        if (i === 0) {
          if ((visibleStart >= start && visibleStart < end) || (visibleEnd > start && visibleEnd <= end)) {
            focused.push(i);
          }
        }
        else {
          if ((visibleStart < end && end <= visibleEnd) || (visibleStart <= start && start < visibleEnd) || (start <= visibleStart && visibleEnd <= end)) {
            focused.push(i);
          }
        }
      }
    }

    for (let i of focused) {
      document.getElementById('period' + i).style.backgroundColor = this.createColor(this.state.periods[i].color, 1);
    }
    this.undoFocusPeriod(focused);

    let newHistory = '/' + this.state.currentLang + '/' + visibleStart + '/' + visibleEnd;
    if (this.state.activeItem._id !== -1) {
      newHistory += '/' + this.state.activeItem._id;
    }
    this.props.history.replace(newHistory);
  }

  undoFocusPeriod(focused) {
    // focused: array of focused period(s)
    let maxWidth = 7.69;
    let minWidth = 0;
    if (focused.length < 13) {
      maxWidth = 75 / focused.length;
      minWidth = 25 / (13 - focused.length);
    }

    for (let i = 0; i < 13; i++) {
      if (focused.indexOf(i) === -1) {
        document.getElementById('period' + i).style.backgroundColor = this.createColor(this.state.periods[i].color, 1);
        document.getElementById('period' + i).style.width = minWidth + '%';
      }
      else {
        document.getElementById('period' + i).style.width = maxWidth + '%';
        document.getElementById('period' + i).style.transform = 'scale(1)';
        document.getElementById('period' + i).style.height = '100%';
        document.getElementById('period' + i).style.marginTop = '0px';
      }
    }
    this.setState({ activePeriods: focused });
  }

  focusPeriod = () => {
    this.setState({ activePeriodFocused: !this.state.activePeriodFocused });
  };

  onMouseEnter = (index, color) => {
    if (this.state.activePeriods.indexOf(index) > -1 && this.state.activePeriods.length === 1) {
      document.getElementById('period' + index).style.transform = 'scale(1)';
      document.getElementById('period' + index).style.height = '100%';
      document.getElementById('period' + index).style.marginTop = '0';
    }
    else if (this.state.activePeriods.indexOf(index) > -1 && this.state.activePeriods.length < 3) {
      document.getElementById('period' + index).style.transform = 'scale(1.05)';
      document.getElementById('period' + index).style.height = '112%';
      document.getElementById('period' + index).style.marginTop = '-18px';
    }
    else {
      document.getElementById('period' + index).style.backgroundColor = this.createColor(color, 1);
      document.getElementById('period' + index).style.transform = 'scale(1.2)';
      document.getElementById('period' + index).style.marginTop = '-12px';
    }
  };

  onMouseLeave = (index, color) => {
    if (this.state.activePeriods.indexOf(index) < 0) {
      document.getElementById('period' + index).style.backgroundColor = this.createColor(color, 1);
    }
    document.getElementById('period' + index).style.transform = 'scale(1)';
    document.getElementById('period' + index).style.height = '100%';
    document.getElementById('period' + index).style.marginTop = '0px';
  };

  createColor(color, opacity) {
    return 'rgba(' + color + ',' + opacity + ')';
  }

  getPeriodYears(year) {
    if (year < 0) {
      return (-1 * year);
    }
    if (year > 0) {
      return year;
    }
  }

  getPeriodSymbols(year) {
    let symbolA = ' π.Χ.';
    let symbolB = ' μ.Χ.';
    if (this.state.currentLang === 'en') {
      symbolA = ' B.C.';
      symbolB = ' A.D.';
    }

    if (year < 0) {
      return symbolA;
    }
    if (year > 0) {
      return symbolB;
    }
  }

  getDescription(description) {
    if (description.length > 300) {
      const limit = description.substr(0, 300).lastIndexOf(' ');
      return description.substr(0, limit) + ' ...';
    }
    return description;
  }

  removeAccents(txt, isPeriod) {
    if (isPeriod && !txt) {
      return null;
    }
    return txt.replace(/ά/g, 'α').replace(/Ά/g, 'Α')
      .replace(/έ/g, 'ε').replace(/Έ/g, 'Ε')
      .replace(/ή/g, 'η').replace(/Ή/g, 'Η')
      .replace(/ί/g, 'ι').replace(/Ί/g, 'Ι')
      .replace(/ό/g, 'ο').replace(/Ό/g, 'Ο')
      .replace(/ύ/g, 'υ').replace(/Ύ/g, 'Υ')
      .replace(/ώ/g, 'ω').replace(/Ώ/g, 'Ω');
  }

  getNextItem = () => {
    const visibleWindow = this.timeline.getWindow();
    const visibleStart = visibleWindow.start.getFullYear();
    const visibleEnd = visibleWindow.end.getFullYear();
    const fullWidth = Math.abs(visibleEnd - visibleStart);
    const halfWidth = fullWidth / 2;

    if (this.state.activeItemIndex < this.state.itemObjects.length - 1) {
      const newIndex = this.state.activeItemIndex + 1;
      this.setState({
        activeItem: {
          _id: -1
        }
      }, () => {
        this.setState({
          activeItem: this.state.itemObjects[newIndex],
          activeItemIndex: newIndex,
          hasNext: newIndex !== this.state.itemObjects.length - 1,
          hasPrev: newIndex !== 0
        }, () => {
          const newStartYear = this.state.itemObjects[newIndex].startYear - halfWidth;
          const newEndYear = this.state.itemObjects[newIndex].startYear + halfWidth;
          this.timeline.setWindow((new Date(newStartYear, 0, 1)).setFullYear(newStartYear), (new Date(newEndYear, 0, 1)).setFullYear(newEndYear));
        });
      });
    }
  };

  getPrevItem = () => {
    const visibleWindow = this.timeline.getWindow();
    const visibleStart = visibleWindow.start.getFullYear();
    const visibleEnd = visibleWindow.end.getFullYear();
    const fullWidth = Math.abs(visibleEnd - visibleStart);
    const halfWidth = fullWidth / 2;

    if (this.state.activeItemIndex > 0) {
      const newIndex = this.state.activeItemIndex - 1;
      this.setState({
        activeItem: {
          _id: -1
        }
      }, () => {
        this.setState({
          activeItem: this.state.itemObjects[newIndex],
          activeItemIndex: newIndex,
          hasNext: newIndex !== this.state.itemObjects.length - 1,
          hasPrev: newIndex !== 0
        }, () => {
          const newStartYear = this.state.itemObjects[newIndex].startYear - halfWidth;
          const newEndYear = this.state.itemObjects[newIndex].startYear + halfWidth;
          this.timeline.setWindow((new Date(newStartYear, 0, 1)).setFullYear(newStartYear), (new Date(newEndYear, 0, 1)).setFullYear(newEndYear));
        });
      });
    }
  };

  getDialogItem(itemId) {
    if (itemId) {
      axios.instance.get('/items/' + itemId)
        .then((response) => {
            for (let imageUrl of response.data.imageUrls) {
              imageUrl.url = sharedEnv.baseURL + '/' + imageUrl.url;
            }

            let index;
            for (let item of this.state.itemObjects) {
              if (item._id === itemId) {
                index = this.state.itemObjects.indexOf(item);
                break;
              }
            }

            this.setState({
              activeItem: response.data,
              activeItemIndex: index,
              dialogOpen: true,
              hasNext: index !== this.state.itemObjects.length - 1,
              hasPrev: index !== 0
            }, () => {
              const visibleWindow = this.timeline.getWindow();
              const visibleStart = visibleWindow.start.getFullYear();
              const visibleEnd = visibleWindow.end.getFullYear();
              this.props.history.replace('/' + this.state.currentLang + '/' + visibleStart + '/' + visibleEnd + '/' + itemId);
            });
          },
          (error) => {
            console.log(error);
          });
    }
  }

  changeLang = (lang) => {
    this.setState({ currentLang: lang }, () => {
      this.props.history.replace('/' + lang);
      this.onRangeChanged();
    });
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      hasNext: true,
      hasPrev: true,
      activeItem: {
        _id: -1
      }
    }, () => {
      const visibleWindow = this.timeline.getWindow();
      const visibleStart = visibleWindow.start.getFullYear();
      const visibleEnd = visibleWindow.end.getFullYear();
      this.props.history.replace('/' + this.state.currentLang + '/' + visibleStart + '/' + visibleEnd);
    });
  };

  openSnackbar = () => {
    this.setState({ snackbarOpen: true }, () => {
      setTimeout(() => {
        this.setState({ snackbarOpen: false });
      }, 3000);
    })
  };

  TransitionUp(props) {
    return <Slide direction="up" {...props} />;
  }

  render() {
    return (
      <div className="Timeline">
        { this.state.loadScreen &&
          <div className="cover">
            <div className="cover-icon"> <CircularProgress/> </div>
          </div>
        }
        { !this.state.viewOk && !this.state.loadScreen &&
          <div className="cover">
            <div className="rotation">
              <ScreenRotationIcon className="rotation-icon animated infinite swing slower" color="primary"/>
            </div>
            {this.state.currentLang === 'en' ? <p className="rotation-text">ROTATE YOUR SCREEN</p> : <p className="rotation-text">ΣΤΡΕΨΤΕ ΤΗΝ ΟΘΟΝΗ ΣΑΣ</p>}
          </div>
        }
        { !this.state.loadScreen && this.state.viewOk &&
          <div className="menu">
            {this.state.activePeriods.length < 13 &&
              <div className="menu-button-container menu-button-container-left">
                <div className="button-container button-arrow-container" onClick={this.menuClick(3)}>
                  <img className="button-content" alt="arrow" src={process.env.PUBLIC_URL + "/images/left.svg"}/>
                </div>
                <div className="button-container button-text-container" onClick={this.menuClick(3)}>
                  <div className="button-content button-text">{this.state.currentLang === 'en' ? 'MOVE' : 'ΚΥΛΙΣΗ'}</div>
                </div>
              </div>
            }
            <div className="languages">
              <div className="language-container">
                <div className="language" onClick={() => this.changeLang('en')}
                     style={{ opacity: this.state.currentLang === 'en' ? 1 : 0.5 }}>ENG</div>
              </div>
              <div className="language-container">
                <div className="museum-button">
                  <a href={sharedEnv.hrefLink} target="_blank" rel="noopener noreferrer">
                    <img className="museum-logo" alt={sharedEnv.hrefAlt} src={process.env.PUBLIC_URL + "/logos/" + sharedEnv.logoFileName}/>
                  </a>
                </div>
              </div>
              <div className="language-container">
                <div className="language" onClick={() => this.changeLang('el')}
                     style={{ opacity: this.state.currentLang === 'el' ? 1 : 0.5 }}>ΕΛ</div>
              </div>
            </div>
            {this.state.activePeriods.length < 13 &&
              <div className="menu-button-container menu-button-container-right">
                <div className="button-container button-text-container" onClick={this.menuClick(4)}>
                  <div className="button-content button-text">{this.state.currentLang === 'en' ? 'MOVE' : 'ΚΥΛΙΣΗ'}</div>
                </div>
                <div className="button-container button-arrow-container" onClick={this.menuClick(4)}>
                  <img className="button-content" alt="arrow" src={process.env.PUBLIC_URL + "/images/right.svg"}/>
                </div>
              </div>
            }
          </div>
        }
        { !this.state.loadScreen && this.state.viewOk &&
          <div id="side-menu">
            <div id="side-menu-container" onClick={() => this.openSidebar()}>
              <div className="side-menu-text-container">
                <div className="side-menu-block">
                  {this.state.currentLang === 'en' ?
                    <img alt="OPTIONS" src={process.env.PUBLIC_URL + "/images/options_en.png"}/>
                    :
                    <img alt="ΕΠΙΛΟΓΕΣ" src={process.env.PUBLIC_URL + "/images/options_el.png"}/>
                  }
                </div>
              </div>
              <div className="side-menu-icon-container">
                <div className="side-menu-block">
                  <img className="options-icon" alt="more" src={process.env.PUBLIC_URL + "/images/options.svg"}/>
                </div>
              </div>
            </div>

            <table id="side-menu-table">
              <tbody>
              <tr>
                <td onClick={() => this.toggleFilters()}>
                  <img alt="filters" src={process.env.PUBLIC_URL + "/images/filters.svg"}/>
                  <br/>
                  {this.state.currentLang === 'en' ? 'FILTERS' : 'ΦΙΛΤΡΑ'}
                </td>
              </tr>
              <tr><td onClick={this.menuClick(1)}><ZoomInIcon className="filter-icon"/> <br/> ZOOM IN</td></tr>
              <tr><td onClick={this.menuClick(2)}><ZoomOutIcon className="filter-icon"/> <br/> ZOOM OUT</td></tr>
              <tr><td onClick={() => this.closeSidebar()}>
                <img alt="close" src={process.env.PUBLIC_URL + "/images/close.svg"}/>
              </td></tr>
              </tbody>
            </table>
          </div>
        }
        { !this.state.loadScreen && this.state.viewOk &&
          <div id="filters">
            {this.state.currentLang === 'en' ? <p>BY TIME RANGE &gt;</p> : <p>ΕΥΡΟΣ ΧΡΟΝΟΥ &gt;</p>}
            <div className="filter-input-container">
              <input
                className="time-input"
                type="text" id="from" name="from" maxLength="4"
                value={this.state.activeFilters.range.start}
                onChange={this.handleFilterChange('start')}>
              </input>
              <div style={{ display: 'inline-block', float: 'right', paddingRight: '5%' }}>
                <span onClick={() => this.timeFilterIsBc('startBC', true)}
                      style={{ opacity: this.state.activeFilters.range.startBC ? 1 : 0.5, marginRight: '20%' }}
                >
                  {this.state.currentLang === 'en' ? 'B.C.' : 'π.Χ.'}
                </span>
                <span onClick={() => this.timeFilterIsBc('startBC', false)}
                      style={{ opacity: this.state.activeFilters.range.startBC ? 0.5 : 1 }}
                >
                  {this.state.currentLang === 'en' ? 'A.D.' : 'μ.Χ.'}
                </span>
              </div>
            </div>
            <div className="filter-input-container" style={{ float: 'right' }}>
              <input
                className="time-input"
                type="text" id="until" name="until" maxLength="4"
                value={this.state.activeFilters.range.end}
                onChange={this.handleFilterChange('end')}>
              </input>
              <div style={{ display: 'inline-block', float: 'right', paddingRight: '5%' }}>
                <span onClick={() => this.timeFilterIsBc('endBC', true)}
                      style={{ opacity: this.state.activeFilters.range.endBC ? 1 : 0.5, marginRight: '20%' }}
                >
                  {this.state.currentLang === 'en' ? 'B.C.' : 'π.Χ.'}
                </span>
                <span onClick={() => this.timeFilterIsBc('endBC', false)}
                      style={{ opacity: this.state.activeFilters.range.endBC ? 0.5 : 1 }}
                >
                  {this.state.currentLang === 'en' ? 'A.D.' : 'μ.Χ.'}
                </span>
              </div>
            </div>
            <div className="range-filter">
              <hr id="colored-line"/>
              <div className="handle" id="left-handle"></div>
              <div className="handle" id="right-handle"></div>
            </div>

            {this.state.currentLang === 'en' ? <p>BY CATEGORY &gt;</p> : <p>ΚΑΤΗΓΟΡΙΑ &gt;</p>}
            <div
              className="category-filter"
              style={{ opacity: this.state.activeFilters.categories.political ? 1 : 0.5 }}
              onClick={() => this.categoryFilterClicked('political')}
            >
              <div className="category-filter-color" style={{ background: this.state.categoryColors[0] }}></div>
              {this.state.currentLang === 'en' ? 'Events' : 'Γεγονότα'}
            </div>
            <div
              className="category-filter"
              style={{ opacity: this.state.activeFilters.categories.exhibits ? 1 : 0.5 }}
              onClick={() => this.categoryFilterClicked('exhibits')}
            >
              <div className="category-filter-color" style={{ background: this.state.categoryColors[1] }}></div>
              {this.state.currentLang === 'en' ? 'Exhibits' : 'Εκθέματα'}
            </div>

            {this.state.currentLang === 'en' ? <p>BY KEYWORD &gt;</p> : <p>ΛΕΞΗ - ΚΛΕΙΔΙ &gt;</p>}
            <input
              className="filter-input keyword-input"
              type="text" id="keyword" name="keyword"
              value={this.state.activeFilters.keywords}
              onChange={this.handleFilterChange('keywords')}>
            </input>

            <br/>
            <div className="filter-buttons">
              <div className="filter-button" style={{ color: '#a6a6a6' }} onClick={() => this.clearFilters()}>
                {this.state.currentLang === 'en' ? 'CLEAR' : 'ΚΑΘΑΡΙΣΜΟΣ'}
              </div>
              {this.state.validFilters ?
                <div className="filter-button" onClick={() => this.applyFilters()}>
                  {this.state.currentLang === 'en' ? 'APPLY' : 'ΕΦΑΡΜΟΓΗ'}
                </div>
                :
                <div className="filter-button" style={{ color: '#a6a6a6', cursor: 'initial !important' }}>
                  {this.state.currentLang === 'en' ? 'APPLY' : 'ΕΦΑΡΜΟΓΗ'}
                </div>
              }
              <div>{this.state.itemObjects.length} {this.state.itemObjects.length === 1 ? (
                this.state.currentLang === 'en' ? 'result' : 'αποτέλεσμα'
              ) : (
                this.state.currentLang === 'en' ? 'results' : 'αποτελέσματα'
              )}</div>
            </div>
          </div>
        }

        <div className="block">
          {!this.state.loadScreen && this.state.viewOk &&
            this.timeline.getWindow().start.getFullYear() === -7000 &&
            this.timeline.getWindow().end.getFullYear() === new Date().getFullYear() &&
            <div className="intro-text">
              {this.state.currentLang === 'en' ? sharedEnv.introTextEN : sharedEnv.introTextGR}
            </div>
          }
          <div id="timeline" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/backgrounds/'+ sharedEnv.backgroundImageFileName +')' }}></div>
        </div>
        <div className="periods-wrap">
          {this.state.periods.map((period, index) => (
            <div
              key={index}
              className={"period" + (this.state.activePeriods.indexOf(index) > -1 && this.state.activePeriods.length === 1 ? (this.state.activePeriodFocused ? " focused-period" : "") : "")}
              id={'period' + index}
              onMouseEnter={() => this.onMouseEnter(index, period.color)}
              onMouseLeave={() => this.onMouseLeave(index, period.color)}
              onClick={this.periodClick(index)}
            >
              {this.state.activePeriods.indexOf(index) !== -1 ? (
                  this.state.activePeriods.length === 1 ?
                    <div className="active-period">
                      <div className="active-period-info">
                        <div className="active-period-name">{this.removeAccents(period.title[this.state.currentLang], true)}</div>
                        <div className="period-years active-period-years">
                          {index !== 0 && <table>
                            <tbody>
                            <tr>
                              <td>{this.getPeriodYears(period.startYear)}</td>
                              <td></td>
                              <td>{this.getPeriodYears(period.endYear)}</td>
                            </tr>
                            <tr>
                              <td>{this.getPeriodSymbols(period.startYear)}</td>
                              <td style={{ textAlign: 'center' }}>-</td>
                              <td>{this.getPeriodSymbols(period.endYear)}</td>
                            </tr>
                            </tbody>
                          </table>}
                        </div>
                      </div>
                      <div className="active-period-description">
                        {this.state.activePeriodFocused ?
                          <SimpleBar autoHide={false} style={{ width: '100%', height: '80%', paddingRight: '18px', paddingBottom: '10px', whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
                            {period.description[this.state.currentLang]}
                          </SimpleBar>
                          :
                          <div className="active-period-description-non-focused">
                            {period.description[this.state.currentLang]}
                          </div>
                        }
                        <div className="active-period-btn">
                          {period.description[this.state.currentLang].length > 300 &&
                            <div className="active-period-btn-text" onClick={this.focusPeriod}>
                              {this.state.currentLang === 'en' ? (
                                this.state.activePeriodFocused ? 'SHOW LESS' : 'SHOW MORE'
                              ) : (
                                this.state.activePeriodFocused ? 'ΛΙΓΟΤΕΡΑ' : 'ΠΕΡΙΣΣΟΤΕΡΑ'
                              )}
                            </div>}
                        </div>
                      </div>
                    </div>
                    :
                    <div className="period-info">
                      <div className="period-name">{this.removeAccents(period.title[this.state.currentLang], true)}</div>
                      <div className="period-years">
                        {index !== 0 && <table>
                          <tbody>
                          <tr>
                            <td>{this.getPeriodYears(period.startYear)}</td>
                            <td> </td>
                            <td>{this.getPeriodYears(period.endYear)}</td>
                          </tr>
                          <tr>
                            <td>{this.getPeriodSymbols(period.startYear)}</td>
                            <td style={{ textAlign: 'center' }}>-</td>
                            <td>{this.getPeriodSymbols(period.endYear)}</td>
                          </tr>
                          </tbody>
                        </table>}
                      </div>
                    </div>
                )
                :
                <div className={"period-transformed-years" + (index === 0 ? " period-transformed-years-hide" : "")}>
                  {this.getPeriodYears(period.startYear)} {this.getPeriodSymbols(period.startYear)} - {this.getPeriodYears(period.endYear)} {this.getPeriodSymbols(period.endYear)}
                </div>
              }
            </div>
          ))}
        </div>

        <Snackbar
          className="Snackbar"
          open={this.state.snackbarOpen}
          TransitionComponent={this.TransitionUp}
          ContentProps={{ 'aria-describedby': 'message-id' }}
          message={<span id="message-id">{this.state.currentLang === 'en' ? 'The url has been copied!' : 'Ο σύνδεσμος αντιγράφηκε!'}</span>}
        />

        <ItemDialog
          lang={this.state.currentLang}
          item={this.state.activeItem}
          categoryColor={this.state.categoryColors[this.state.activeItem.category]}
          isOpen={this.state.dialogOpen}
          handleClose={this.handleClose}
          getNext={this.getNextItem}
          getPrev={this.getPrevItem}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
          windowStart={this.timeline ? this.timeline.getWindow().start.getFullYear() : ''}
          windowEnd={this.timeline ? this.timeline.getWindow().end.getFullYear() : ''}
          openSnackbar={this.openSnackbar}
        />
      </div>
    );
  }
}

export default Timeline;
