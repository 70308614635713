import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

import './ImageFull.css';

function TransitionDown(props) {
	return <Slide direction="down" {...props} />;
}

const imageFull = (props) => {
	return (
		<Dialog
			classes={{paper: 'full-img-paper'}}
			open={props.isOpen}
			onClose={props.handleClose}
			TransitionComponent={TransitionDown}
			keepMounted
			aria-labelledby="dialog-img"
			aria-describedby="dialog-img"
		>
			<div className="image-full-container">
				<div className="image-full-close" onClick={event => props.handleClose()}>
					<CloseIcon style={{ fontSize: 30, color: 'white' }}/>
				</div>
				<img className="item-full-img" alt="item-img" src={props.image}/>
			</div>
		</Dialog>
	);
};

export default imageFull;
