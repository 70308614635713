import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import axios from '../../shared/axios-url';

function getCleanState() {
  return {
    hasError: false,
    errorCode: 0
  };
}

class DeleteDialog extends Component {

  constructor(props) {
    super(props);
    this.state = getCleanState();
  }

  deleteItem() {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth': this.props.getToken()
      }
    };
  
    axios.instance.delete('/items/' + this.props.item, config)
    .then((response) => {
      this.handleClose();
    },
    (error) => {
      this.setState({
        hasError: true,
        errorCode: error.response.status
      }, () => {
        setTimeout(() => {
          if (this.state.errorCode === 401) {
            localStorage.clear();
            window.location.reload();
          }
          else {
            this.handleClose();
          }
        }, 3000);
      });
    });
  }

  handleClose = () => {
    this.setState(getCleanState(), () => {
      this.props.handleClose();
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        aria-labelledby="delete-dialog"
        aria-describedby="delete-dialog"
        classes={{ paper: 'AdminDialogPaper' }}
      >
        <DialogContent>
          {this.state.hasError ? (
            this.state.errorCode === 401 ?
              <div>
                <p className="errorText">Συνδεθείτε και προσπαθήστε ξανά!</p>
                <CircularProgress size={24} 
                  color="primary" 
                  style={{ marginLeft: '40%' }}
                />
              </div>
            :
              <div>
                <p className="errorText">Κάτι πήγε στραβά, προσπαθήστε ξανά!</p>
                <div style={{ width: '100%', padding: '0 34%' }}>
                  <Button 
                    color="primary"
                    onClick={this.handleClose}
                  >
                    Ok
                  </Button>
                </div>
              </div>
          ) : 
          <div>
            <p style={{ fontSize: '120%' }}>Είστε βέβαιοι πως θέλετε να γίνει διαγραφή;</p>
            <br/>
            <Button 
              color="primary"
              onClick={this.handleClose}
            >
              Οχι
            </Button>
            <Button 
              color="secondary"
              onClick={event => this.deleteItem()}
            >
              Ναι
            </Button>
          </div>}
        </DialogContent>
      </Dialog>
    )
  }
}

export default DeleteDialog;
